import { default as indexvsa0KwJWqiMeta } from "/src/pages/index.vue?macro=true";
import { default as _91id_93Qd0xwJluFzMeta } from "/src/pages/order/[id].vue?macro=true";
import { default as privacy9K1kFlJGupMeta } from "/src/pages/privacy.vue?macro=true";
import { default as voorwaardencnZXEDNwV2Meta } from "/src/pages/voorwaarden.vue?macro=true";
export default [
  {
    name: indexvsa0KwJWqiMeta?.name ?? "index",
    path: indexvsa0KwJWqiMeta?.path ?? "/",
    meta: indexvsa0KwJWqiMeta || {},
    alias: indexvsa0KwJWqiMeta?.alias || [],
    redirect: indexvsa0KwJWqiMeta?.redirect || undefined,
    component: () => import("/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Qd0xwJluFzMeta?.name ?? "order-id",
    path: _91id_93Qd0xwJluFzMeta?.path ?? "/order/:id()",
    meta: _91id_93Qd0xwJluFzMeta || {},
    alias: _91id_93Qd0xwJluFzMeta?.alias || [],
    redirect: _91id_93Qd0xwJluFzMeta?.redirect || undefined,
    component: () => import("/src/pages/order/[id].vue").then(m => m.default || m)
  },
  {
    name: privacy9K1kFlJGupMeta?.name ?? "privacy",
    path: privacy9K1kFlJGupMeta?.path ?? "/privacy",
    meta: privacy9K1kFlJGupMeta || {},
    alias: privacy9K1kFlJGupMeta?.alias || [],
    redirect: privacy9K1kFlJGupMeta?.redirect || undefined,
    component: () => import("/src/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: voorwaardencnZXEDNwV2Meta?.name ?? "voorwaarden",
    path: voorwaardencnZXEDNwV2Meta?.path ?? "/voorwaarden",
    meta: voorwaardencnZXEDNwV2Meta || {},
    alias: voorwaardencnZXEDNwV2Meta?.alias || [],
    redirect: voorwaardencnZXEDNwV2Meta?.redirect || undefined,
    component: () => import("/src/pages/voorwaarden.vue").then(m => m.default || m)
  }
]