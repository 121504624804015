import * as Sentry from "@sentry/vue";
import type { BrowserOptions } from "@sentry/vue";

export default defineNuxtPlugin((app) => {
  Sentry.init({
    app: app.vueApp,
    dsn: app.$config.public.sentry.dsn,
    environment: app.$config.public.app_env,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(useRouter()),
      }),
      new Sentry.Replay(),
    ],
  } as BrowserOptions);
});
